.ace__contact-content{
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px){
    .ace__contact-content{
        flex-direction: column;
        padding: 2rem 0rem 2rem 0rem;
    }
}

.ace__contact-content__form-submit p{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 400px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}

.ace__contact-content__social{
    padding: 0rem 4rem 2rem 4rem;
}

.ace__contact-content__social h4{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: justify;
    text-transform: uppercase;
    padding-bottom: 1rem;
    
    }

    @media screen and (max-width:768px){
        .ace__contact-content__social{
            padding: 0rem 0rem 2rem 0rem;
        }
        
    }


    @media screen and (max-width:560px) {
        .ace__contact-text {
            padding: 0rem 0rem;
    
        }
        
    }

.ace__contact-content__form{
    padding: 0rem 2rem;
    flex: 0.75;
}

@media screen and (max-width:768px) {
    .ace__contact-content__form{
        padding: 0;
    }
    
}

.ace__contact-content__form h4{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: justify;
    text-transform: uppercase;
    padding-bottom: 1rem;
    
    }

.ace__contact-content__form-field input{
    font-family: var(--font-family);
    padding: 0.5rem 2rem;
    width: 100%;
    height: 40px;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--color-bg-2);
    color: var(--color-text);
    border: none;
}

.ace__contact-content__form-field textarea{
    font-family: var(--font-family);
    padding: 0.5rem 2rem;
    width: 100%;
    height: 150px;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--color-bg-2);
    border: none;
    color: var(--color-text);
    border: none;
}

.ace__contact-content__form-field button{
    padding: 14px 44px;
    width: 100%;
    height: 50px;
    left: 20px;
    top: 20px;
    background: var(--color-sub);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    font-family: var(--font-family);
    color: var(--color-bg);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border:none;
    text-transform: uppercase;
}

.ace__contact-content__form-field button:hover{
    scale: 1.05;
    transition: 1s ease-out;
}
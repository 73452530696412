@import url(https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap);
*{
    box-sizing: border-box;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;

}

/*body*/
body {
background-color:  var(--color-bg);
} 

.ace__bg{
  background-color:  var(--color-bg);

}

/*links*/
a {
    color: unset;
    text-decoration: none;
}

/*section padding and margin*/
.section__padding {
    padding: 4rem 6rem;
}

.section__padding-home{
    padding: 6rem 0rem 2rem 0rem;
}

.section__padding-footer{
  padding: 2rem 2rem;
}

@media screen and (max-width:768px) {
    .section__padding{
        padding: 2rem;
    }
    
}

.section__margin {
    padding: 0rem 0rem;
}


/*animation scale up */
.scale-up-tr {
	-webkit-animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* animation scale-up-tr */
 @-webkit-keyframes scale-up-tr {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }
  @keyframes scale-up-tr {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }

/* Background Gradient
  .ace__bg-gradient{
background:-moz-linear-gradient(150deg, rgba(57, 34, 98, 1) 0%, rgba(30, 30, 30, 1) 24%); 
background:-webkit-linear-gradient(150deg, rgba(57, 34, 98, 1) 0%, rgba(30, 30, 30, 1) 24%);
background:-o-linear-gradient(150deg, rgba(57, 34, 98, 1) 0%, rgba(30, 30, 30, 1) 24%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1E1E1E', endColorstr='#392262', GradientType=1 );
background:-ms-linear-gradient(150deg, rgba(57, 34, 98, 1) 0%, rgba(30, 30, 30, 1) 24%);
background:linear-gradient(150deg, rgba(57, 34, 98, 1) 0%, rgba(30, 30, 30, 1) 24%);
}
  */

/* button scale up */
  .scale-up-ver-bottom {
    -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
@-webkit-keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}


/* button vibrate */
.vibrate-1 {
	-webkit-animation: vibrate-1 0.3s linear infinite both;
	        animation: vibrate-1 0.3s linear infinite both;
}
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

/* text shadow pop up */
.text-pop-up-right {
	-webkit-animation: text-pop-up-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: text-pop-up-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes text-pop-up-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}


/* text focus in */
.focus-in-expand {
	-webkit-animation: focus-in-expand 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-expand 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
 @-webkit-keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

/*fade in image*/
.fade-in-left {
	-webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


/* reveal section*/
.reveal{
        position: relative;
        -webkit-transform: translateY(150px);
                transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
      }
      
      .reveal.active{
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
      }


.ace__about{
    display: flex;
    align-content: flex-start;
    justify-content: center;
    flex-direction: column;
}

.ace__about-content{
    display: flex;
    flex-direction: row;
    padding: 4rem 0rem;
}

@media screen and (max-width: 768px) {
    .ace__about-content{
    flex-direction: column;
    }
    
}

.ace__about-text{
    flex: 0.75 1;
    padding: 0rem 4rem;
}

.ace__about-text p{
font-family: var(--font-family);
color: var(--color-text);
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: justify;
padding-bottom: 2rem;
}

.ace__about-social h4{
font-family: var(--font-family);
color: var(--color-text);
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 19px;
text-align: justify;
text-transform: uppercase;
padding-bottom: 1rem;

}

@media screen and (max-width:768px){
    .ace__about-social{
        padding: 0rem 4rem;
    }
    
}

@media screen and (max-width:560px) {
    .ace__about-text {
        padding: 0rem 0rem;

    }

    .ace__about-social {
        padding: 0rem 0rem;

    }
    
}
.ace__brand{
    display: flex;
    flex-direction: row;
    padding: 1rem;
}

.ace__brand p{
font-family: var(--font-family);
color: var(--color-text);
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
text-align: justify;
padding: 0rem 1rem;
}

.ace__brand img{
    height: 24px;
}
.ace__brand-small{
    display: flex;
    flex-direction: row;
    padding: 0.25rem;
}

.ace__brand-small p{
font-family: var(--font-family);
color: var(--color-text);
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
text-align: justify;
padding: 0rem 1rem;
}


.ace__brand-small img{
    height:16px;
}
.ace__heading{
    display: flex;
    flex-direction: column;
}

.ace__heading-title h2{
font-family: var(--font-family);
color: var(--color-text);
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
text-transform: uppercase;
}

.ace__heading-sub h3{
font-family: var(--font-family);
color: var(--color-text);
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 56px;
color: var(--color-text);
}

.ace__portfolio {
    background-color: white;
    padding: 1rem;
    margin: 1rem;
    border-radius: 0.5rem;
}

.ace__portfolio img {
 width: 500px;
 height: 500px;
 border-radius: 0.5rem;
 object-fit: cover;
box-shadow:0px 0px 10px 5px #dedede ;
-webkit-filter: grayscale(100%);
        filter: grayscale(100%);
}

.ace__portfolio p {
    font-family: var(--font-family);
    font-weight: 500;
    padding: 1rem;
    text-align: center;
}

.ace__portfolio:hover{
    scale: 1.05;
    transition: 0.5s;
}

.ace__portfolio:hover img{
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
    transition: 0.5s;
}

.ace__portfolio:hover p{
color: var(--color-bg);

}

  .video-responsive {
    background-color: var(--color-sub);
    margin: 1rem;
    padding: 1rem;
    border-radius: 10px;
  }

.video-responsive iframe {
    border-radius: 1rem;
  }

  .video-responsive a {
    background-color: var(--color-bg);
    padding: 0.3rem 1rem;
    color: var(--color-text);
    border-radius: 1rem;
  }

  .video-responsive a:hover {
    transition: 0.5s ease-out;
    letter-spacing: 0.5px;
    color: var(--color-text);

  }



.ace__contact-content{
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px){
    .ace__contact-content{
        flex-direction: column;
        padding: 2rem 0rem 2rem 0rem;
    }
}

.ace__contact-content__form-submit p{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 400px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}

.ace__contact-content__social{
    padding: 0rem 4rem 2rem 4rem;
}

.ace__contact-content__social h4{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: justify;
    text-transform: uppercase;
    padding-bottom: 1rem;
    
    }

    @media screen and (max-width:768px){
        .ace__contact-content__social{
            padding: 0rem 0rem 2rem 0rem;
        }
        
    }


    @media screen and (max-width:560px) {
        .ace__contact-text {
            padding: 0rem 0rem;
    
        }
        
    }

.ace__contact-content__form{
    padding: 0rem 2rem;
    flex: 0.75 1;
}

@media screen and (max-width:768px) {
    .ace__contact-content__form{
        padding: 0;
    }
    
}

.ace__contact-content__form h4{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: justify;
    text-transform: uppercase;
    padding-bottom: 1rem;
    
    }

.ace__contact-content__form-field input{
    font-family: var(--font-family);
    padding: 0.5rem 2rem;
    width: 100%;
    height: 40px;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--color-bg-2);
    color: var(--color-text);
    border: none;
}

.ace__contact-content__form-field textarea{
    font-family: var(--font-family);
    padding: 0.5rem 2rem;
    width: 100%;
    height: 150px;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--color-bg-2);
    border: none;
    color: var(--color-text);
    border: none;
}

.ace__contact-content__form-field button{
    padding: 14px 44px;
    width: 100%;
    height: 50px;
    left: 20px;
    top: 20px;
    background: var(--color-sub);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    font-family: var(--font-family);
    color: var(--color-bg);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border:none;
    text-transform: uppercase;
}

.ace__contact-content__form-field button:hover{
    scale: 1.05;
    transition: 1s ease-out;
}
.ace__footer{
    background-color: var(--color-bg-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ace__footer p{
font-family: var(--font-family);
color: var(--color-text);
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
text-align: justify;
}

.ace__footer img{
    width: 200px;
}

@media screen and (max-width:768px) {
    .ace__footer{
        flex-direction: column;
    }

    .ace__footer p{
        padding: 2rem;
    }
    
}
.ace__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
    position: fixed;
    z-index: 20;
    background-color: var(--color-bg);
    top: 0;
    right: 0;
    left: 0;
}

.ace__navbar img {
    width: 234px;
    height: 34px;
    justify-content: flex-start;
    align-items: center;
}

.ace__navbar-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 2rem;
}

.ace__navbar-links a{
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    padding: 0.5rem 1rem 0.5rem 1rem;
    

}

.ace__navbar-links a:hover{
    color: var(--color-bg);
    transition: 1s ease-out;
    letter-spacing: 2px;
    background-color: var(--color-text);
    border-radius: 0.5rem;

}

.ace__navbar-menu{
    margin-left: 1rem;
    display: none; 
    position: relative;
}

.ace__navbar-menu__container-links{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background-color: var(--color-bg);
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
}

.ace__navbar-menu a{
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    padding: 1rem;
}

@media screen and (max-width: 991px){
    .ace__navbar{
        padding: 1rem;
        top: 0;
    }
    
    .ace__navbar-links{
        display: none;
    }

    .ace__navbar-menu{
        display: flex;
    }
    
}


.ace__services-list{
    display: flex;
    justify-content: space-around;
    padding: 4rem;
    flex-wrap: wrap;
}

.ace__services-list__item {
    width: 250px;
    background-color: var(--color-bg-2);
    padding: 2rem;
    margin: 1rem;
    border-radius: 0rem 4em 0rem 0rem;
}

@media screen and (max-width:768px){
.ace__services-list{
    padding: 2rem 0rem;
}
    
}



.ace__services-list__item svg{
    color: aliceblue;
    font-size: 75px;
    padding-bottom: 1rem;
}

.ace__services-list__item h5 {
    font-family: var(--font-family);
    font-size: 20px;
    color: var(--color-text);
    padding-bottom: 1rem;
}

.ace__services-list__item li{
    font-family: var(--font-family);
    font-size: 16px;
    color: var(--color-text);
    list-style-type: none;
    line-height: 32px;
}

.ace__services-list__item hr{
    opacity: 10%;
}

.ace__skills-brand{
    padding: 4rem 4rem;
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px){
    .ace__skills-brand{
        padding: 2rem 2rem;
    }
}

.ace__skills-bar{
    background-color: var(--color-sub);
    border-radius: 2rem;
    padding: 1rem 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media screen and (max-width:468px) {
    .ace__skills-bar{
        flex-direction: column;
        margin: 2rem;
        padding: 1rem 1rem 1rem 3rem;
    }
    
}

.ace__skills-bar__items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ace__skills-bar h5 {
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: 800;
    color: var(--color-bg);
}

.ace__skills-bar p {
    font-family: var(--font-family);
    font-size: 16px;
    color: var(--color-bg);
}

.ace__skills-bar hr {
    color: var(--color-bg);
}
.ace__home{
    display:flex;
}

.ace__home-content{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0rem 0rem 0rem 6rem;

}

.ace__home-content h1{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 800;
    font-size: 96px;
    line-height: 80px;
}

.ace__home-content p{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 2rem 0rem;
}

.ace__home-content a{
    padding: 14px 44px;
    width: 200px;
    height: 50px;
    left: 20px;
    top: 20px;
    background: var(--color-text);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    font-family: var(--font-family);
    color: var(--color-bg);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border:none;
    text-transform: uppercase;


}

.ace__home-content a:hover{
    background: var(--color-sub);
    width: 220px;
    color: var(--color-bg);
    transition: 1s ease-out;
}

.ace__home-bg{
    display:flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1;
}

.ace__home-bg img{
    width: 100%;
    height: 100%;
}

@media screen and ( max-width: 768px ) {
    .ace__home{
        flex-direction: column;
    }

    .ace__home-content{
        padding: 4rem 2rem 2rem 2rem;
    }

    .ace__home-content h1{
        font-size: 75px;
    }
}

@media screen and (max-width:425px) {
    .ace__home-content h1{
        font-size: 50px;
        line-height: 45px;
    }
    
}
.ace__work a:hover h5{
    scale: 1.03;
    transition: 0.2s;

}

.ace__work-arrow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.ace__work-arrow h5 {
    font-family: var(--font-family);
    font-size: 20px;
    color: var(--color-text);
    padding-bottom: 1rem;
}

.ace__work-arrow img {
    width: 32px;
    height: 100%;
    margin: 0rem 1rem;
    display: flex;
}

.ace__work-portfolio{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 0rem;
    justify-content: center;  

}

.ace__work-portfolio img{
    width: 200px;
    height: 200px;
}

.ace__work-portfolio__list{
    display: flex;
    width: 800px;
    cursor: grab;
}

@media screen and (max-width:768px) {
    .ace__work-portfolio__list{
        display: flex;
        width: 600px;
    }
    
}

@media screen and (max-width:620px) {
    .ace__work-portfolio__list{
        display: flex;
        width: 350px;
    }
    
}

@media screen and (max-width:425px) {
    .ace__work-portfolio__list{
        display: flex;
        width: 280px;
    }
    
}

.ace__work-button a{
    padding: 14px 44px;
    width: 200px;
    height: 50px;
    left: 20px;
    top: 20px;
    background: var(--color-text);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    font-family: var(--font-family);
    color: var(--color-bg);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border:none;
    text-transform: uppercase;
    display: flex;
}

.ace__work-button a:hover{
    background: var(--color-sub);
    width: 220px;
    color: var(--color-bg);
    transition: 1s ease-out;
}

.ace__work-portfolio a{
    color: var(--color-bg);
}

.ace__work-portfolio a:hover{
    color: var(--color-bg);
}
.ace__projects-filter {
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.ace__projects-filter button {
        padding: 10px 10px;
        width: 150px;
        height: 50px;
        left: 20px;
        top: 20px;
        background: var(--color-sub);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 11px;
        font-family: var(--font-family);
        color: var(--color-bg);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        border:none;
        text-transform: uppercase;
        margin: 0rem 0.5rem;
}

.ace__projects-portfolio{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 0rem;
    justify-content: center;  

}

.ace__projects-portfolio img{
    width: 200px;
    height: 200px;
}

.ace__gallery{
    padding: 8rem 0rem 0rem 0rem;
}

.react-tabs__tab p {
    color: var(--color-text);
  }

  .react-tabs__tab--selected p{
    color: var(--color-bg-2);
  }

  .ace__gallery-portfolio{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 0rem;
    justify-content: center;  

}

.ace__gallery-portfolio img{
  width: 200px;
  height: 200px;
}

.ace__NoPage{
    background-color: var(--color-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.ace__NoPage_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ace__NoPage_content h2{
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 150px;
    line-height: 1rem;
}

.ace__NoPage_content h3{
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 36px;
    text-align: center;
}

.ace__NoPage_content p{
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 14px;
    text-align: center;
}

.ace__NoPage_content a{
    color: var(--color-bg);
    font-family: var(--font-family);
    background-color: var(--color-sub);
    padding: 0.5rem 1rem;
    border-radius: 1rem;
}

.ace__NoPage_content a:hover{
    transition: 0.5s ease-out;
    letter-spacing: 0.5px;
    color: var(--color-bg);

}
:root {
    --color-bg: #1E1E1E;
    --color-bg-2: #1B1A1A;
    --color-text: #ffffff;
    --color-sub:#D9D9D9 ;
    --font-family: 'Maven Pro', sans-serif; 
}

.ace__projects-filter {
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.ace__projects-filter button {
        padding: 10px 10px;
        width: 150px;
        height: 50px;
        left: 20px;
        top: 20px;
        background: var(--color-sub);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 11px;
        font-family: var(--font-family);
        color: var(--color-bg);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        border:none;
        text-transform: uppercase;
        margin: 0rem 0.5rem;
}

.ace__projects-portfolio{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 0rem;
    justify-content: center;  

}

.ace__projects-portfolio img{
    width: 200px;
    height: 200px;
}

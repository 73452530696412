.ace__heading{
    display: flex;
    flex-direction: column;
}

.ace__heading-title h2{
font-family: var(--font-family);
color: var(--color-text);
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
text-transform: uppercase;
}

.ace__heading-sub h3{
font-family: var(--font-family);
color: var(--color-text);
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 56px;
color: var(--color-text);
}

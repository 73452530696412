.ace__home{
    display:flex;
}

.ace__home-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0rem 0rem 0rem 6rem;

}

.ace__home-content h1{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 800;
    font-size: 96px;
    line-height: 80px;
}

.ace__home-content p{
    font-family: var(--font-family);
    color: var(--color-text);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 2rem 0rem;
}

.ace__home-content a{
    padding: 14px 44px;
    width: 200px;
    height: 50px;
    left: 20px;
    top: 20px;
    background: var(--color-text);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    font-family: var(--font-family);
    color: var(--color-bg);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border:none;
    text-transform: uppercase;


}

.ace__home-content a:hover{
    background: var(--color-sub);
    width: 220px;
    color: var(--color-bg);
    transition: 1s ease-out;
}

.ace__home-bg{
    display:flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

.ace__home-bg img{
    width: 100%;
    height: 100%;
}

@media screen and ( max-width: 768px ) {
    .ace__home{
        flex-direction: column;
    }

    .ace__home-content{
        padding: 4rem 2rem 2rem 2rem;
    }

    .ace__home-content h1{
        font-size: 75px;
    }
}

@media screen and (max-width:425px) {
    .ace__home-content h1{
        font-size: 50px;
        line-height: 45px;
    }
    
}
.ace__work a:hover h5{
    scale: 1.03;
    transition: 0.2s;

}

.ace__work-arrow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.ace__work-arrow h5 {
    font-family: var(--font-family);
    font-size: 20px;
    color: var(--color-text);
    padding-bottom: 1rem;
}

.ace__work-arrow img {
    width: 32px;
    height: 100%;
    margin: 0rem 1rem;
    display: flex;
}

.ace__work-portfolio{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 0rem;
    justify-content: center;  

}

.ace__work-portfolio img{
    width: 200px;
    height: 200px;
}

.ace__work-portfolio__list{
    display: flex;
    width: 800px;
    cursor: grab;
}

@media screen and (max-width:768px) {
    .ace__work-portfolio__list{
        display: flex;
        width: 600px;
    }
    
}

@media screen and (max-width:620px) {
    .ace__work-portfolio__list{
        display: flex;
        width: 350px;
    }
    
}

@media screen and (max-width:425px) {
    .ace__work-portfolio__list{
        display: flex;
        width: 280px;
    }
    
}

.ace__work-button a{
    padding: 14px 44px;
    width: 200px;
    height: 50px;
    left: 20px;
    top: 20px;
    background: var(--color-text);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    font-family: var(--font-family);
    color: var(--color-bg);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border:none;
    text-transform: uppercase;
    display: flex;
}

.ace__work-button a:hover{
    background: var(--color-sub);
    width: 220px;
    color: var(--color-bg);
    transition: 1s ease-out;
}

.ace__work-portfolio a{
    color: var(--color-bg);
}

.ace__work-portfolio a:hover{
    color: var(--color-bg);
}